import React, {useState} from 'react';
import styled from 'styled-components';
import {NavLink, useParams, useLocation} from "react-router-dom";

import Icon from "../../common/components/Icon.js";


import Image from '../../common/components/Image.js';
// import Button from './../../common/components/Button';

// import {BREAKPOINT_SM} from '../../config.js';

const Wrapper = styled.div`

  border-right: 2px solid #41424C;
  border-image-source: linear-gradient( 180deg, #41424C 0%, #41424C 40%, #41424C 70%, #41424C00 95% );
  border-image-slice: 1;
    
  width: 100%;
  min-height: 100%;
  // box-shadow: 0 2px 8px rgba(0,0,0,0.08);
  // background-color: #ffffff;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
`;


const Top = styled.div`
  width: 100%;
  user-select: none;
`;
const Bottom = styled.div`
  width: 100%;
  text-align: center;
  
  font-size: 15px;
  font-weight: 400;
  line-height: 14px;
  padding-bottom: 5px;
`;


const Brand = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;
const Photo = styled.div`
  margin-top: 8px;
  border-bottom: 2px solid #000000;
  border-image-slice: 1;
  border-image-source: linear-gradient( 90deg, #000000 0%, #000000 10%, #000000 60%, #ffffff00 );
  width: 198px;
  height: 300px;
  // background-color: #dedede;
  overflow: hidden;
  margin-bottom: 10px;
`;
const Name = styled.div`
  color: green !important;
  text-align: center;
`;
const MainName = styled.div`
  font-weight: 700;
  font-size: 23px;
  margin-bottom: 5px;
`;
const SubName = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
`;




const MenuCnt = styled.div`
  @media (max-width: 991px){
    position: fixed;
    top: 80px;
    left: ${props => props.isOpen ? "0px" : "-100vw"};
    width: 100vw;
    height: calc(100vh - 80px);
    background-color: #ffffff;
    transition: all 0.25s ease-in-out;
    overflow-x: hidden;
    overflow-y: scroll;
  }
`;

const MobiIconsCnt = styled.div`
  z-index: 5;
  line-height: 0px;
  display: flex;
  @media (min-width: 992px){
    display: none;
  }
`;




const Nav = styled.div`
  overflow: hidden;

  white-space: nowrap;
  display: flex;
  flex-direction: column;
  list-style: none;

  @media (min-width: 992px){
    align-items: center;
  }

  @media (max-width: 991px){

    flex-direction: column;
    text-align: center;
    box-shadow: 0 0.125rem 0.25rem 0 rgba(0,0,0,.075) inset;
    max-height: calc(100vh - 70px);
    overflow-x: hidden;
    overflow-y: auto;
  }
`;
const Link = styled(NavLink)`
  font-size: 18px;
  float: left;
  display: block;
  margin: 5px 0px;
`;

const Item = styled.span`
  font-weight: 700;
  font-size: 19px; 
 
  
  // background: ${props => props.active ? "linear-gradient( 90deg, #ccff0000 0%, #ccff0080 5%, #ccff0080 10%, #ccff0080 90%, #ccff0000)" : "none"};
  
  transition: all 0.45s;
`;


const MobiToggle = styled.div`
  margin-left: 10px;
  cursor: pointer;
`;

const Sidebar = () => {
  const {lang} = useParams();
  const { pathname } = useLocation();
  const [isOpen, setOpen] = useState(false);

  return (
    <Wrapper>
      <Top>
        <Brand to={"/"+lang}>
          {/* <Logo />   */}
          <Photo>
            <Image src="web/avatar.png" />
          </Photo>
          <Name>
            <MainName>Sara Potyscki</MainName>
            <SubName>Front-End<br/>Web Developer</SubName>
          </Name>
        </Brand>
        <MenuCnt isOpen={isOpen}>
          <Nav>
            <Link exact="true" to={"/"+(lang ? lang : "it")+"/"}>
              {({ isActive }) => (<Item active={isActive}>Home</Item>)}
            </Link>
            <Link to={"/"+(lang ? lang : "it")+"/about"}>
              <Item active={pathname.includes('/about')}>
                About
              </Item>
            </Link>
            <Link to={"/"+(lang ? lang : "it")+"/services"}>
              <Item active={pathname.includes('/services')}>
                Services
              </Item>
            </Link>
            <Link to={"/"+(lang ? lang : "it")+"/portfolio"}>
              <Item active={pathname.includes('/portfolio')}>
                Portfolio
              </Item>
            </Link>
            <Link exact="true" to={"/"+(lang ? lang : "it")+"/contacts"}>
              {({ isActive }) => (<Item active={isActive}>Contatti</Item>)}
            </Link>
          </Nav>
        </MenuCnt>
        <MobiIconsCnt>
          <MobiToggle onClick={() => setOpen(!isOpen)}>
            <Icon name="menu" size="24px" />
          </MobiToggle>
        </MobiIconsCnt>
      
      </Top>
      <Bottom>
        &copy;{new Date().getFullYear()} Sara Potyscki<br/>
        P.IVA: 02590520207
      </Bottom>
    </Wrapper>
    
  )
}

export default Sidebar;

