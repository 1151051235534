import {useState, useCallback, useEffect} from 'react'; //useContext
import {Routes, Route, Navigate} from 'react-router-dom'; //useLocation
// import {UserContext} from './common/contexts/UserContext';
// import { getAnalytics } from "firebase/analytics";


//Frontend
import Home from "./frontend/pages/Home";
import About from "./frontend/pages/About";
import Services from "./frontend/pages/Services";
import Portfolio from "./frontend/pages/Portfolio";
import Contacts from "./frontend/pages/Contacts";


// import BlogList from "./frontend/pages/BlogList";
// import BlogSingle from "./frontend/pages/BlogSingle";


// import CinquePerMille from "./frontend/pages/CinquePerMille";

//Authentication
// import Registration from "./backend/pages/Registration";
// import Login from "./backend/pages/Login";

// import AdminDashboard from './backend/pages/Dashboard';
// import AdminHorsesList from './backend/pages/HorsesList';
// import AdminHorsesSingle from './backend/pages/HorsesSingle';

//404
import NotFound from "./frontend/pages/NotFound";

import {BREAKPOINT_MD, BREAKPOINT_SM, BREAKPOINT_XS, LAYOUT_LG, LAYOUT_MD, LAYOUT_SM, LAYOUT_XS } from './config';

// const RequireAuth = ({children}: {children: JSX.Element}) => {
//   const {user} = useContext(UserContext);
//   let location = useLocation();
//   // console.log(user);
//   if (!user) {
//     return <Navigate to="/admin/login" state={{ from: location }} replace />;
//   }
//   return (<>{children}</>);
// }

const App = () => {
  const [resizeListener, setResizeListener] = useState(false);
  const [layout, setLayout] = useState(null);

  // useEffect(() => {
  //   AnalyticsService.getUrl(src).then((response) => {
  //     if(response.success){
  //       setUrl(response.url);
  //     }else{
  //       console.log(response.error);
  //     }
  //   });
  // }, []);

  const handleResize = useCallback(() => {
    setLayout(window.innerWidth <= BREAKPOINT_XS ? LAYOUT_XS : (window.innerWidth <= BREAKPOINT_SM ? LAYOUT_SM : (window.innerWidth <= BREAKPOINT_MD ? LAYOUT_MD : LAYOUT_LG)));
  }, []);

  useEffect(() => {
    if(!resizeListener){
      setResizeListener(true);
      window.addEventListener("resize", handleResize, false);
      handleResize();
    }
  }, [handleResize, resizeListener]);

  return (
    <Routes>

      {/*301*/}
      <Route exact path='/' element={<Navigate to={"/en"} />} />

      {/*Frontend*/}
      <Route exact path='/:lang/' element={<Home layout={layout} />} />      
      <Route exact path='/:lang/about' element={<About layout={layout} />} />
      <Route exact path='/:lang/services' element={<Services layout={layout} />} />
      <Route exact path='/:lang/portfolio' element={<Portfolio layout={layout} />} />
      <Route exact path='/:lang/contacts' element={<Contacts layout={layout} />} />

        
      {/*Redirect*/}    
      {/* <Route exact path='/:lang/chi-siamo' element={<Navigate to={"/it/associazione"} />} /> */}

      {/*Authentication*/}
      {/*<Route exact path='/admin/registration' element={<Registration />} />*/}
      {/* <Route exact path='/admin/login' element={<Login />} /> */}

      {/*Backend*/}
      {/* <Route exact path='/admin' element={<RequireAuth><AdminDashboard layout={layout} /></RequireAuth>} />
      <Route exact path='/admin/horses' element={<RequireAuth><AdminHorsesList layout={layout} /></RequireAuth>} />
      <Route exact path="/admin/horses/:slug" element={<RequireAuth><AdminHorsesSingle layout={layout} /></RequireAuth>} /> */}

      {/*404*/}
      <Route path='*' element={<NotFound layout={layout} />} />
    </Routes>
  );
}

export default App;
