import React, { useContext } from 'react'; //useEffect
import styled from 'styled-components';
// import { useLocation } from "react-router-dom";
import { OverlayContext } from './../../common/contexts/OverlayContext';

import Sidebar from "./Sidebar";
import Overlay from "./../../common/components/Overlay";

// import DatabaseService from './../../common/services/DatabaseService';

// import { BREAKPOINT_SM } from '../../config';

const Wrapper = styled.div`
  position: relative;
  background-image: radial-gradient(#00000010 1px, transparent 0);
  background-size: 20px 20px;

  transform: rotate(-0.30deg) scale(0.97) translate(3px, -3px);
  border-left: 2px solid #41424C;
  border-right: 2px solid #41424C;
  border-image-source: linear-gradient( 180deg, #41424C 0%, #41424C 20%, #41424C 90%, #41424C00 100% );
  border-image-slice: 1;
  
  position: relative;
  width: 100vw;
  height: 100vh;

  overflow-x: visible;
  overflow-y: visible;

  &::before{
    display: block;
    content: "";
    border-bottom: 2px solid #41424C;
    border-image-slice: 1;
    border-image-source: linear-gradient( 90deg, #41424C 0%, #41424C 90%, #41424C00 );
    position: absolute;
    top: 5px;
    left: -10px;
    width: calc(100% + 40px);
    
  }

  &::after{
    display: block;
    content: "";
    border-top: 2px solid #41424C;
    border-image-slice: 1;
    border-image-source: linear-gradient( 90deg, #41424C 0%, #41424C 90%, #41424C00 );
    position: absolute;
    bottom: -1px;
    left: -5px;
    width: calc(100% - 60px);
    
  }


  a{
    color: red;
    border-bottom: 2px solid transparent;
    line-height: 20px;
  }
  a:hover, a.active{
    color: red !important;
    text-decoration: none;
    border-bottom: 2px solid #ff0000;
    border-image-slice: 1;
    border-image-source: linear-gradient( 90deg, #ff0000 0%, #ff0000 85%, #ff000000 100%);    
  }

  


`;

const SubWrapper = styled.div`
  // width: 100vw;
  // height: 100vh;
  // // border-top: 2px solid #41424C;
  // border-bottom: 2px solid #41424C;
  // border-image-slice: 1;
  // border-image-source: linear-gradient( 90deg, #41424C 0%, #41424C 90%, #41424C00 );
  // // border: 1px solid red;
`;

const SidebarWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 200px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;
const PageWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  width: calc(100vw - 200px);
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 30px;
`;

// function ScrollToTop() {
//   const { pathname } = useLocation();

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   return null;
// }

const Layout = ({ children }) => {
  const {overlay, setOverlay} = useContext(OverlayContext);
  // const [horses, setHorses] = useState([]);

  // useEffect(() => {
  //   DatabaseService.getHorses({order: "random", showWeb: true}).then((response) => {
  //     if(response.success){
  //       setHorses(response.data);
  //     }else{
  //       console.log(response.error);
  //     }
  //   });    
  // }, []);

  return (
    <>
      <Wrapper>
        <SubWrapper>
          {/* <ScrollToTop /> */}
          <SidebarWrapper>
            <Sidebar />
          </SidebarWrapper>
          <PageWrapper>
            {children}
          </PageWrapper>
        </SubWrapper>
      </Wrapper>

      { overlay ? 
        <Overlay fnClose={() => setOverlay(null)}>
          {overlay}
        </Overlay>
      : null }
    </>
  )
}

export default Layout
