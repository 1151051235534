import { initializeApp } from "@firebase/app";
import { getStorage, ref, getDownloadURL, listAll, uploadBytes } from "@firebase/storage";


import {firebaseConfig} from './../../config';

class StorageService {
  constructor() {
    this.app = initializeApp(firebaseConfig);
    this.storage = getStorage();
  }

  list(){
    return new Promise((resolve, reject) => {
      listAll(ref(this.storage)).then((response) => {
        resolve(response.items);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getUrl(url){
    return new Promise((resolve, reject) => {
      getDownloadURL(ref(this.storage, url)).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  addBlob(file, name){
    return new Promise((resolve, reject) => {
      uploadBytes(ref(this.storage, name+".webp"), file).then((snapshot) => {
        resolve(true);
      });
      // getDownloadURL(ref(this.storage, url)).then((response) => {
      //   let res = {};
      //   res.success = true;
      //   res.url = response;
      //   resolve(res);
      // }).catch((error) => {
      //   reject(error);
      // });
    });
  }

}


let instance = new StorageService();
export default instance;
