import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {UserContextProvider} from './common/contexts/UserContext';
import {OverlayContextProvider} from './common/contexts/OverlayContext';
import App from './App';
import './index.css';

// import {loadStripe} from '@stripe/stripe-js';
// import { Elements } from '@stripe/react-stripe-js';

const root = ReactDOM.createRoot(document.getElementById('root'));
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
root.render(
  <React.StrictMode>
    <UserContextProvider>
      <OverlayContextProvider>
        {/* <Elements stripe={stripePromise}> */}
          <BrowserRouter>
            <App />
          </BrowserRouter>
        {/* </Elements> */}
      </OverlayContextProvider>
    </UserContextProvider>
  </React.StrictMode>
);
