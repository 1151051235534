import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import StorageService from '../../common/services/StorageService';

const Img = styled.img`
  max-width: 100%;
`;

const Image = ({ 
  src, 
  alt = "",
  width="100%",
  height=null
}) => {
  const [url, setUrl] = useState([]);

  useEffect(() => {
    StorageService.getUrl(src).then((response) => {
      setUrl(response);
    });
  }, [src]);

  return (
    <Img width={width} height={height} src={url ? url : null} alt={alt}  />
  )
}

export default Image;
