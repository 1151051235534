import React from 'react';
import styled from 'styled-components';

// import StorageService from '../../common/services/StorageService';

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  width: ${props => props.size};
  height: ${props => props.size};
  line-height: ${props => props.size};
  margin: ${props => props.margin};
  color: ${props => props.color};

  &, & * {
    box-sizing: border-box;
    font-size: calc(${props => props.size} * 1);
    color: inherit;
  }

`;




const Icon = ({
  name = "disabled_by_default",
  color = "#000000",
  size = "20px",
  margin = "0px"
}) => {

  return (
    <Wrapper size={size} margin={margin} color={color}>
      <span className="material-symbols-outlined">
        {name}
      </span>
    </Wrapper>
  )
}

export default Icon
