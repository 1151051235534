import React, {useEffect} from 'react';
// import styled from 'styled-components';
import {useParams} from 'react-router-dom';

import AnalyticsService from '../../common/services/AnalyticsService';

import Meta from '../layout/Meta';
import Layout from '../layout/Layout';
// import Section from '../layout/Section';
// import Padding from '../layout/Padding';
// import Row from '../../common/components/Row';
// import Col from '../../common/components/Col';
// import Typo from '../../common/components/Typo';
// import Cta from '../../common/components/Cta';



const About = ({layout}) => {
  let { lang } = useParams();

  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  return (
    <Layout layout={layout}>
      <Meta
        title={lang === "it" ? "About" : "About"}
        languages={{"it": "about", "en":"about"}}
      />

      W.I.P.
    </Layout>
  )
}

export default About
