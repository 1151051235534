export const firebaseConfig = {
  apiKey: "AIzaSyBJqKr7Ca5_bzJ33Ot8dOaWSuk8Grvf8as",
  authDomain: "pixel-perfect-65151.firebaseapp.com",
  projectId: "pixel-perfect-65151",
  storageBucket: "pixel-perfect-65151.appspot.com",
  messagingSenderId: "191846781725",
  appId: "1:191846781725:web:e5574a7b745730d66dc7f4",
  measurementId: "G-9K6E7MQ18R"
};

export const BREAKPOINT_XS = 768 - 1;
export const BREAKPOINT_SM = 992 - 1;
export const BREAKPOINT_MD = 1400 - 1;
export const LAYOUT_XS = "XS";
export const LAYOUT_SM = "SM";
export const LAYOUT_MD = "MD";
export const LAYOUT_LG = "LG";
export const PAGESIZE = 20;